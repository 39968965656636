import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
	FaHeart,
	FaExclamationTriangle,
	FaRegTimesCircle,
	FaRegClock,
	FaRegHeart,
	FaDownload,
	FaCommentAlt,
	FaRegCommentAlt
} from "react-icons/fa";
import format from "date-fns/format";
import VideoPlayer from "./MediaPlayer";
import Comments from "./Comments/Comments";
import Parse from "html-react-parser";
import { handleEditRemoveButtons } from "./BlogUtils";
import { RemovePostModal } from "./PostsManagement/RemovePostModal";

export default function SinglePost({
	post,
	familyLikes,
	isBlogger,
	isModerator,
	canRemovePost,
	userState,
	blogDispatch
}) {
	const { t } = useTranslation("common");
	const [showRemoveConfirmation, setShowRemoveConfirmation] = useState({
		postId: null,
		postTitle: null
	});
	const displayMedias = medias => {
		const video = medias.filter(media => media.content_type === "video/mp4");
		const pdfFiles = medias.filter(media => media.content_type === "application/pdf");
		const pictures = medias.filter(media => media.content_type.split("/")[0] === "image");
		return (
			<>
				{pdfFiles.length > 0 &&
					pdfFiles.map(pdf => (
						<div key={pdf.path} className="header-details">
							<a href={`/storages/blog_media${pdf.path}`} download={`${pdf.filename}`}>
								<FaDownload className="mr-2" />
								<b>{pdf.filename}</b> ({t("common.__pdfFile__")})
							</a>
						</div>
					))}
				{video.length > 0 &&
					video.map(v => (
						<div key={v.path} className="center">
							<div className="video-container">
								<VideoPlayer uriVideo={v.path} contentType={v.content_type} />
							</div>
						</div>
					))}
				{pictures.length > 0 && (
					<div className="pictures-container">
						{pictures.map(pict => (
							<div key={pict.path} className="single-post-pict">
								<img
									src={`/storages/blog_media${pict.path}`}
									className="full-pict-post"
									alt="blog illustration"
								/>
								<a
									href={`/storages/blog_media${pict.path}`}
									download={`${pict.path_original}`}
									className="single-post-pict-download">
									<div className="single-post-pict-download-content">
										<FaDownload className="single-post-pict-download-icon" />
									</div>
								</a>
							</div>
						))}
					</div>
				)}
			</>
		);
	};

	return (
		<>
			{post.title ? (
				<div className="social-block mt-4">
					<div className="d-flex justify-content-between">
						<h2>{post.title}</h2>
						<div>
							{handleEditRemoveButtons(
								post.accepted,
								post.added_by,
								post.id,
								post.title,
								isBlogger,
								canRemovePost,
								userState.userConnected.id,
								isModerator,
								setShowRemoveConfirmation
							)}
						</div>
					</div>
					<div className="separator"></div>
					<div>
						<span className="meta">{format(new Date(post.date.split(" ")[0]), "P")}</span>
						{post.accepted && (
							<b className="green ml-4">
								<FaRegClock className="mr-2" />
								{t("news.__moderationOk__")}
							</b>
						)}
						{post.accepted === null && (
							<Link to="/moderation">
								<b className="blue ml-4">
									<FaExclamationTriangle className="mr-2" />
									{t("news.__moderationPending__")}
								</b>
							</Link>
						)}
						{post.accepted === false && (
							<span className="red">
								<FaRegTimesCircle className="mr-2 ml-4" />
								{t("news.__moderationRefused__")}
							</span>
						)}
						{post.like_counter > 0 ? (
							<span className="red">
								<FaHeart className="mr-2 ml-4" /> <b>{post.like_counter}</b>
							</span>
						) : (
							<span className="red mr-2">
								<FaRegHeart className=" ml-4" /> 0
							</span>
						)}
						{post.comment_counter > 0 ? (
							<span className="blue">
								<FaRegCommentAlt className="blue mr-2 ml-4" /> <b>{post.comment_counter}</b>
							</span>
						) : (
							<span className="blue">
								<FaCommentAlt className="mr-2 ml-4" /> 0
							</span>
						)}
					</div>
					{post.focused_on ? (
						<div className="mt-2">
							{post.seniors_focused_on.map(senior => (
								<span key={senior.id} className="senior-badge mr-2" style={{ color: "#fff" }}>
									{senior.last_name.toUpperCase()} {senior.first_name}
								</span>
							))}
						</div>
					) : null}
					<div className="mt-5">{Parse(post.body)}</div>
					<div className="mt-4">{displayMedias(post.medias)}</div>
					{post.like_counter > 0 ? (
						<div className="header-details mt-4 mb-4">
							{familyLikes.map(family => (
								<span key={family.id} style={{ display: "inline-block" }}>
									<FaRegHeart className="mr-2 red" />
									<b className={"grey mr-4"}>
										{family.first_name} {family.last_name}
									</b>
								</span>
							))}
						</div>
					) : null}
					{post.accepted ? (
						<Comments
							postId={post.id}
							commentsCounter={post.comment_counter}
							userState={userState}
							blogDispatch={blogDispatch}
						/>
					) : null}
					<br />
				</div>
			) : null}
			<RemovePostModal
				show={showRemoveConfirmation}
				setShow={setShowRemoveConfirmation}
				blogDispatch={blogDispatch}
				redirection={true}
			/>
		</>
	);
}
