import React, { useContext } from "react";
import { Route } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { useForm, FormProvider } from "react-hook-form";
import { UserContext } from "../context/UserContext";
import MainLayout from "../layout/MainLayout";
import Login from "../pages/Login";
import NewPassword from "../components/User/NewPassword";
import UserAccount from "../pages/UserAccount";
import Flow from "../pages/Flow";
import Post from "../pages/Post";
import CreatePost from "../pages/CreatePost";
import UpdateMyPost from "../pages/UpdatePost";
import PostsManagement from "../pages/PostsManagement";
import Calendar from "../pages/Calendar";
import Calenday from "../pages/Calenday";
import LiveMeeting from "../components/Jitsi/LiveMeeting";
import CalendarAddEvent from "../pages/CalendarAddEvent";
import CalendWeek from "../pages/CalendWeek";
import Occupation from "../pages/Occupation";
import OccupationDetails from "../pages/OccupationDetails";

function ProtectedApp() {
	const methods = useForm();
	const queryClient = new QueryClient();
	const { userState, userDispatch, seniors, homeUnits, selectedDate, setSelectedDate } = useContext(
		UserContext
	);

	return (
		<>
			<Route exact path="/" component={Login} />
			<Route exact path="/newpassword/:token" component={NewPassword} />
			<MainLayout>
				<Route exact path="/livemeeting/:subject/:room" render={() => <LiveMeeting />} />
				{/* Calendar */}
				<Route
					exact
					path="/calendar"
					render={() => (
						<Calendar
							userState={userState}
							seniors={seniors}
							selectedDate={selectedDate}
							setSelectedDate={setSelectedDate}
						/>
					)}
				/>
				<Route
					exact
					path="/calenday"
					render={() => (
						<Calenday
							userState={userState}
							seniors={seniors}
							selectedDate={selectedDate}
							setSelectedDate={setSelectedDate}
						/>
					)}
				/>
				<Route
					exact
					path="/calendweek"
					render={() => (
						<CalendWeek
							userState={userState}
							seniors={seniors}
							selectedDate={selectedDate}
							setSelectedDate={setSelectedDate}
						/>
					)}
				/>
				<Route
					exact
					path="/occupation/:eventId"
					render={() => (
						<Occupation
							userState={userState}
							seniors={seniors}
							selectedDate={selectedDate}
							setSelectedDate={setSelectedDate}
							homeUnits={homeUnits}
						/>
					)}
				/>
				<Route
					exact
					path="/occupation/:eventId/:occupationId"
					render={() => (
						<Occupation
							userState={userState}
							seniors={seniors}
							selectedDate={selectedDate}
							setSelectedDate={setSelectedDate}
							homeUnits={homeUnits}
						/>
					)}
				/>
				<Route
					exact
					path="/workshop/:eventId/:occupationId"
					render={() => <OccupationDetails seniors={seniors} />}
				/>
				{/* Blogging */}
				<Route
					exact
					path="/myposts"
					render={() => <PostsManagement userState={userState} seniors={seniors} />}
				/>
				<QueryClientProvider client={queryClient}>
					<Route exact path="/post/:id" render={() => <Post userState={userState} />} />
					<Route exact path="/flow" render={() => <Flow userState={userState} seniors={seniors} />} />
					<FormProvider {...methods}>
						<Route
							exact
							path="/:param/addpost"
							render={() => <CreatePost userState={userState} seniors={seniors} homeUnits={homeUnits} />}
						/>
						<Route
							exact
							path="/:param/editpost/:id"
							render={() => <UpdateMyPost userState={userState} seniors={seniors} homeUnits={homeUnits} />}
						/>
						<Route
							exact
							path="/addevent"
							render={() => (
								<CalendarAddEvent
									userState={userState}
									selectedDate={selectedDate}
									setSelectedDate={setSelectedDate}
								/>
							)}
						/>
					</FormProvider>
				</QueryClientProvider>

				{/* User Settings */}
				<Route exact path="/myaccount" render={() => <UserAccount userDispatch={userDispatch} />} />
			</MainLayout>
		</>
	);
}

export default ProtectedApp;
