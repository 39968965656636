import React, { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import { BlogContext } from "../context/BlogContext";
import { CAN_POST_MODIFY_HOME_BLOG, HOME_BLOG_WRITER } from "../constants/Permissions";
import AccessControl from "../layout/AccessControl";
import EditPost from "../components/Blog/EditPost";
import http from "../services/interceptor";
import * as url from "../constants/Url";
import {
	HomeUnitsInterface,
	SeniorInterface,
	UserInterface
} from "../interfaces/UserStateInterface";

interface UpdatePostProps {
	userState: UserInterface;
	seniors: SeniorInterface[];
	homeUnits: HomeUnitsInterface[];
}

export default function UpdatePost({ userState, seniors, homeUnits }: UpdatePostProps) {
	const { blogDispatch } = useContext(BlogContext);
	const { id } = useParams<{ id?: string }>();
	const [post, setPost] = useState(null);

	useEffect(() => {
		if (id) {
			http
				.get(`${url.HOMES_URL}/blog_posts/${id}`)
				.then(res => setPost(res.data.blog_post))
				.catch(() => setPost(null));
		}
	}, [id]);

	return (
		<AccessControl
			userPermissions={userState.userConnected.permissions}
			permissionNeeded={[HOME_BLOG_WRITER, CAN_POST_MODIFY_HOME_BLOG]}>
			<div className="content-layout">
				{post ? (
					<EditPost
						userState={userState}
						seniors={seniors}
						post={post}
						blogDispatch={blogDispatch}
						homeUnits={homeUnits}
					/>
				) : null}
			</div>
		</AccessControl>
	);
}
