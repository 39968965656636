import React, { useRef, useEffect, useState } from "react";
import videojs from "video.js";
import "video.js/dist/video-js.css";

export default function MediaPlayer({ uriVideo, contentType, poster }) {
	const playerRef = useRef(null);
	const videoSrc = `/storages/blog_media${uriVideo}`;
	const posterIncluded = poster ? poster : [];
	const posterSrc = posterIncluded.length > 0 ? `/storages/blog_media${poster[0].path}` : null;
	const [downloadLink, setDownloadLink] = useState(false);

	const videoJsOptions = {
		controls: true,
		autoPlay: false,
		playsInline: true,
		muted: true,
		fluid: true,
		bigPlayButton: true,
		poster: posterSrc,
		sources: [
			{
				src: videoSrc,
				type: contentType
			}
		],
		preload: "metadata",
		playbackRates: [0.5, 1, 1.5, 2],
		children: {
			mediaLoader: true,
			controlBar: {
				children: [
					"playToggle",
					"volumePanel",
					"currentTimeDisplay",
					"timeDivider",
					"durationDisplay",
					"progressControl",
					"liveDisplay",
					"remainingTimeDisplay",
					"customControlSpacer",
					"playbackRateMenuButton",
					"audioTrackButton",
					"fullscreenToggle"
				]
			}
		}
	};

	useEffect(() => {
		if (playerRef) {
			const player = videojs(playerRef.current, videoJsOptions, () => {
				player.on("error", function () {
					const err = player.error();
					if (err.code === 4) {
						player.dispose();
						setDownloadLink(true);
					}
					player.dispose();
				});
				// player.ready(function () {
				//     // player.play() //start on load
				// })
			});
			return () => {
				player.dispose();
			};
		}
	}, []);

	return (
		<>
			<div data-vjs-player>
				<video
					ref={playerRef}
					className="video-js big-play-centered"
					style={{ width: "100%", background: "transparent" }}
				/>
			</div>

			{downloadLink && (
				<div className="story-card">
					<p>
						Votre navigateur ne permet pas de visualiser la vidéo.{" "}
						<a href={videoSrc} download>
							<strong>Téléchargez la video.</strong>
						</a>
					</p>
				</div>
			)}
		</>
	);
}
