import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useMutation, useQueryClient } from "react-query";
import format from "date-fns/format";
import { handleDate } from "@e4ia/e4link_modules.datetime";
import { Progressbar } from "@e4ia/e4link_modules.progressbar";
import {
	HomeUnitsInterface,
	SeniorInterface,
	UserInterface
} from "../../interfaces/UserStateInterface";
import { PostInterface } from "../../interfaces/BlogInterface";
import { ModeratePostModal } from "./PostsManagement/ModeratePostModal";
import PostManager from "./PostsManagement/PostManager";
import HandleError from "../../layout/HandleError";
import http from "../../services/interceptor";
import * as url from "../../constants/Url";
import * as p from "../../constants/Permissions";

interface EditPostProps {
	userState: UserInterface;
	seniors: SeniorInterface[];
	post: PostInterface;
	homeUnits: HomeUnitsInterface[];
	blogDispatch: Function;
}

export default function EditPost({
	userState,
	seniors,
	post,
	blogDispatch,
	homeUnits
}: EditPostProps) {
	const { t } = useTranslation("common");
	const queryClient = useQueryClient();
	const history = useHistory();
	const homeId = userState.userConnected.home_id;
	const [isLoading, setIsLoading] = useState(false);
	const [isError, setIsError] = useState(false);
	const [uploadSize, setUploadSize] = useState(0);
	const [postContent, setPostContent] = useState(post.body);
	const [medias, setMedias] = useState([]);
	const [editMedias, setEditMedias] = useState(false);
	const [initialMedias, setInitialMedias] = useState(post.medias);
	const [title, setTitle] = useState(post.title);
	const initialDate = handleDate(post.date, "yyyy-MM-dd");
	const [selectedDate, setSelectedDate] = useState(initialDate);
	const initialSeniors = post.seniors_focused_on;
	const initialSeniorsSelectedIds = initialSeniors.map(senior => senior.id);
	const initialSeniorsSelected = seniors.filter(senior =>
		initialSeniorsSelectedIds.includes(senior.id)
	);
	const [seniorsSelected, setSeniorsSelected] = useState(initialSeniorsSelectedIds);
	const [progress, setProgress] = useState({ size: 0, maxSize: 0 });
	const canModeratePost = userState.userConnected.is(p.CAN_MODERATE_HOME_BLOG);
	const [showModeration, setShowModeration] = useState({ show: false, post: null });

	const onSubmit = async (e: any) => {
		e.preventDefault();
		if (postContent.length > 3 && title.length > 3 && homeId) {
			setIsLoading(true);
			setIsError(false);
			const postDate = format(new Date(selectedDate), "yyyy-MM-dd' '00:00:00");
			let blogPostData = new FormData();
			if (!editMedias) {
				blogPostData.append("title", title);
				blogPostData.append("body", postContent);
				blogPostData.append("home_id", homeId.toString());
				blogPostData.append("date", postDate);
				blogPostData.append("senior_ids", JSON.stringify(seniorsSelected));
				await mutate(blogPostData);
			} else {
				const existingMedias = initialMedias.map(media => media.path_original);
				for (let i = 0; i < medias.length; i++) {
					blogPostData.append("medias[]", medias[i]);
				}
				for (let i = 0; i < existingMedias.length; i++) {
					blogPostData.append("existing_medias[]", existingMedias[i]);
				}
				blogPostData.append("title", title);
				blogPostData.append("body", postContent);
				blogPostData.append("home_id", homeId.toString());
				blogPostData.append("date", postDate);
				blogPostData.append("senior_ids", JSON.stringify(seniorsSelected));
				await mutate(blogPostData);
			}
		} else {
			setIsError(true);
		}
	};

	const { mutate } = useMutation(
		(blogPostData: any) =>
			http.put(
				editMedias
					? `${url.HOMES_URL}/blog_posts/${post.id}`
					: `${url.HOMES_URL}/blog_posts/${post.id}/without_medias`,
				blogPostData,
				{
					onUploadProgress: progressEvent => {
						setProgress({ size: progressEvent.loaded, maxSize: progressEvent.total + 1000 });
					}
				}
			),
		{
			onSuccess: res => {
				blogDispatch({
					type: "EDIT_POST",
					payload: { post: res.data.blog_post, locale: userState.userConnected.locale }
				});
				setIsLoading(false);
				queryClient.invalidateQueries("blog_posts");
				if (res.data.blog_post.accepted === null && canModeratePost) {
					setShowModeration({ show: true, post: res.data.blog_post });
				} else {
					window.scrollTo({
						top: 0,
						left: 0,
						behavior: "smooth"
					});
					history.push("/myposts");
				}
			},
			onError: () => {
				window.scrollTo({
					top: 0,
					left: 0,
					behavior: "smooth"
				});
				setIsLoading(false);
				setIsError(true);
			}
		}
	);

	return (
		<>
			<h1>{t("news.__updatePost__")}</h1>

			{isLoading ? (
				<div className="header-details center">
					<Progressbar
						size={progress.size}
						maxSize={progress.maxSize}
						isLoader={true}
						alertMessage={t("news.__addNewsLoading__")}
					/>
				</div>
			) : (
				<form onSubmit={onSubmit} encType="multipart/form-data">
					{isError ? (
						<HandleError message={t("news.__error__")} type="error" setError={setIsError} />
					) : null}
					<PostManager
						medias={medias}
						setMedias={setMedias}
						editMedias={editMedias}
						setEditMedias={setEditMedias}
						seniors={seniors}
						postContent={postContent}
						setPostContent={setPostContent}
						title={title}
						setTitle={setTitle}
						selectedDate={selectedDate}
						setSelectedDate={setSelectedDate}
						initialSeniorsSelected={initialSeniorsSelected}
						setSeniorsSelected={setSeniorsSelected}
						initialMedias={initialMedias}
						setInitialMedias={setInitialMedias}
						uploadSize={uploadSize}
						setUploadSize={setUploadSize}
						seniorsSelected={seniorsSelected}
						homeUnits={homeUnits}
					/>
				</form>
			)}
			<ModeratePostModal
				show={showModeration}
				setShow={setShowModeration}
				userLocale={userState.userConnected.locale}
				blogDispatch={blogDispatch}
			/>
		</>
	);
}
