import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { EventInterface } from "../../interfaces/EventInterface";
import { handleDate } from "@e4ia/e4link_modules.datetime";
import http from "../../services/interceptor";
import * as url from "../../constants/Url";
import { displayCategory } from "./CalendarUtils";
import {
	BsCalendarWeek,
	BsInfoSquare,
	BsPeopleFill,
	BsPersonPlusFill,
	BsXLg
} from "react-icons/bs";
import { Link } from "react-router-dom";
import * as types from "../../constants/CalendarEntries";
import { SeniorInterface } from "../../interfaces/UserStateInterface";
import HandleError from "../../layout/HandleError";
import { RemoveEventButtons } from "./RemoveEvent";

interface EventDetailModalProps {
	show: { show: boolean; event: EventInterface | null };
	setShow: Function;
	userLocale: string;
	seniors: SeniorInterface[];
}

export default function EventDetailModal({
	show,
	setShow,
	userLocale,
	seniors
}: EventDetailModalProps) {
	const { t } = useTranslation("common");
	const event: EventInterface | null = show.event;
	const eventRecurringId = event?.recurring_entry_id;
	const [recurringData, setRecurringData] = useState<any>({});
	const [attendees, setAttendees] = useState([]);
	const [error, setError] = useState(false);

	useEffect(() => {
		if (eventRecurringId) {
			http
				.get(`${url.BASE_URL}/home_calendar_entries/${eventRecurringId}`)
				.then((res: any) => setRecurringData(res.data.calendar_entry.recurring_data))
				.catch(() => setRecurringData({}));
		}
	}, [eventRecurringId]);

	useEffect(() => {
		if (event && event.occupation_id && seniors.length > 0) {
			http
				.get(`${url.BASE_URL}/occupations/${event.occupation_id}`)
				.then((res: any) => {
					const seniorsId = res.data.occupation.senior_ids;
					const seniorsSelected = seniorsId.map((id: number) =>
						seniors.find(senior => senior.id === id)
					);
					const seniorsToDisplay = seniorsSelected.filter((senior: any) => senior !== undefined);
					setAttendees(seniorsToDisplay);
				})
				.catch(() => setAttendees([]));
		}
	}, [event, seniors]);

	const handleDays = (days: number[]) => {
		if (days) {
			if (days.length === 7) {
				return t("calendar.__everyDays__");
			} else if (days.length === 1) {
				return (
					<>
						{t("calendar.__thisDay__")} {types.displayDay(days[0])}
					</>
				);
			} else {
				return (
					<p>
						{t("calendar.__thisDay__")} :
						{days.map(day => (
							<span key={day} className="ml-2">
								{types.displayDay(day)} -
							</span>
						))}
					</p>
				);
			}
		}
	};

	return event && show.show ? (
		<div className="overlay">
			<div className="dialog px-4 py-4">
				<BsXLg
					className="right"
					role="button"
					onClick={() => setShow({ show: false, event: null, success: false })}
				/>
				<h2>{displayCategory(event.entry_type_id)}</h2>
				<div className="separator"></div>
				{error ? (
					<HandleError message={t("common.__delError__")} setError={setError} type="error" />
				) : null}
				<h3 className="red">
					{event.entry_type_id === types.VISIO ? (
						<>
							{t("common.__senior__")} : {event.senior}
						</>
					) : (
						event.info
					)}
				</h3>
				<div className="d-flex justify-content-between">
					<p>
						{t("calendar.__appointments__")} : {handleDate(event.begin_datetime, "PPPPp", userLocale)}
						{event.end_datetime ? (
							<>
								<br />
								{t("calendar.__until__")} {handleDate(event.end_datetime, "p", userLocale)}
							</>
						) : null}
					</p>
					{event.occupation_id ? null : (
						<Link to={`/occupation/${event.id}`}>
							<button type="button" className="btn btn-lg btn-primary">
								<BsPersonPlusFill />
							</button>
						</Link>
					)}
				</div>
				{event.detail ? (
					<p>
						<BsInfoSquare className="social-icon mr-3" />
						{event.detail}
					</p>
				) : null}
				{event.occupation_id && attendees.length > 0 ? (
					<>
						<div className="separator"></div>
						<div className="d-flex justify-content-between">
							<div>
								<BsPeopleFill className="social-icon mr-3" />
								{attendees.map((senior: SeniorInterface) => (
									<span key={senior.id} className="meta mr-3">
										{senior.first_name} {senior.last_name}
									</span>
								))}
							</div>
							<div className="right">
								<Link to={`/occupation/${event.id}/${event.occupation_id}`}>
									<button className="btn btn-sm btn-outline-secondary" type="button">
										{t("common.__update__")}
									</button>
								</Link>
							</div>
						</div>
					</>
				) : null}
				{/* {event.entry_type_id === 11 ? displayStartVisioButton(event, "day") : null} */}
				{event.recurring_entry_id &&
					(recurringData ? (
						<>
							<div className="separator"></div>
							<div className="d-flex">
								<div>
									<BsCalendarWeek className="social-icon mr-3" />
								</div>
								<div>
									{recurringData.recurring_type === 2 ? (
										<>
											<b>{t("calendar.__recurring__")}</b> :
											{t("calendar.__dayInMonth__", { day: recurringData.day_of_month })}
										</>
									) : (
										<>
											<b>{t("calendar.__recurring__")}</b> : {handleDays(recurringData.days_of_week)}
										</>
									)}
									{recurringData.end_date ? (
										<p className="meta">
											{t("calendar.__until__")} {handleDate(recurringData.end_date, "PPPP", userLocale)}
										</p>
									) : (
										<p className="meta">{t("calendar.__noEnd__")}</p>
									)}
								</div>
							</div>
						</>
					) : null)}
				<br />
				<RemoveEventButtons event={event} setError={setError} setShow={setShow} />
			</div>
		</div>
	) : null;
}
