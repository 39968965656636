import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import AccessControl from "../layout/AccessControl";
import { CAN_POST_MODIFY_HOME_BLOG, HOME_BLOG_WRITER } from "../constants/Permissions";
import AddNews from "../components/Blog/AddNews";
import { BlogContext } from "../context/BlogContext";
import {
	UserInterface,
	SeniorInterface,
	HomeUnitsInterface
} from "../interfaces/UserStateInterface";
import http from "../services/interceptor";
import * as url from "../constants/Url";
import { UserContext } from "../context/UserContext";

interface CreatePostProps {
	userState: UserInterface;
	seniors: SeniorInterface[];
	homeUnits: HomeUnitsInterface[];
}

export default function CreatePost({ userState, seniors, homeUnits }: CreatePostProps) {
	const { t } = useTranslation("common");
	const { blogDispatch } = useContext(BlogContext);
	const { setHomeUnits } = useContext(UserContext);

	useEffect(() => {
		if (userState.isLogged && userState.userConnected.home_id) {
			http
				.get(`${url.HOMES_URL}/${userState.userConnected.home_id}/home_units?supervisors=true`)
				.then(res => setHomeUnits(res.data.home_units))
				.catch(() => setHomeUnits([]));
		}
	}, [userState.isLogged, userState.userConnected.home_id]);

	return (
		<AccessControl
			userPermissions={userState.userConnected.permissions}
			permissionNeeded={[HOME_BLOG_WRITER, CAN_POST_MODIFY_HOME_BLOG]}>
			<h1>{t("news.__addNews__")}</h1>
			<div className="content-layout">
				<AddNews
					userState={userState}
					seniors={seniors}
					blogDispatch={blogDispatch}
					homeUnits={homeUnits}
				/>
			</div>
		</AccessControl>
	);
}
