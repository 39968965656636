import User from "./UserClass";

const UserReducer = (state, action) => {
	switch (action.type) {
		case "LOGIN":
			return {
				...state,
				isLogged: true,
				userConnected: new User(
					action.payload.first_name,
					action.payload.last_name,
					action.payload.type_id,
					action.payload.permissions,
					action.payload.home_id,
					action.payload.id,
					action.payload.locale
				)
			};

		case "UPDATE_USER":
			return {
				...state,
				userConnected: new User(
					action.payload.first_name,
					action.payload.last_name,
					action.payload.type_id,
					action.payload.permissions,
					action.payload.home_id,
					action.payload.id,
					action.payload.locale
				)
			};

		case "LOGOUT":
			return {
				...state,
				isLogged: false
			};

		default:
			return state;
	}
};

export default UserReducer;
