import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useMutation, useQueryClient } from "react-query";
import { handleDate } from "@e4ia/e4link_modules.datetime";
import { Progressbar } from "@e4ia/e4link_modules.progressbar";
import {
	HomeUnitsInterface,
	SeniorInterface,
	UserInterface
} from "../../interfaces/UserStateInterface";
import PostManager from "./PostsManagement/PostManager";
import http from "../../services/interceptor";
import * as url from "../../constants/Url";
import HandleError from "../../layout/HandleError";
import * as p from "../../constants/Permissions";
import { ModeratePostModal } from "./PostsManagement/ModeratePostModal";

interface AddNewsProps {
	homeUnits: HomeUnitsInterface[];
	seniors: SeniorInterface[];
	blogDispatch: Function;
	userState: UserInterface;
}

export default function AddNews({ userState, seniors, blogDispatch, homeUnits }: AddNewsProps) {
	const { t } = useTranslation("common");
	const history = useHistory();
	const queryClient = useQueryClient();
	const homeId = userState.userConnected.home_id;
	const [isLoading, setIsLoading] = useState(false);
	const [isError, setIsError] = useState(false);
	const [title, setTitle] = useState("");
	const [postContent, setPostContent] = useState(" ");
	const today = handleDate(new Date(), "yyyy-MM-dd");
	const [selectedDate, setSelectedDate] = useState(today);
	const [medias, setMedias] = useState([]);
	const [seniorsSelected, setSeniorsSelected] = useState([]);
	const [uploadSize, setUploadSize] = useState(0);
	const [progress, setProgress] = useState({ size: 0, maxSize: 0 });
	const canModeratePost = userState.userConnected.is(p.CAN_MODERATE_HOME_BLOG);
	const [showModeration, setShowModeration] = useState({ show: false, post: null });

	const onSubmit = async (e: any) => {
		e.preventDefault();
		if (postContent.length > 3 && title.length > 3 && homeId) {
			setIsLoading(true);
			setIsError(false);

			const postDate = handleDate(new Date(selectedDate), "yyyy-MM-dd' '00:00:00");
			const blogPostData = new FormData();
			for (let i = 0; i < medias.length; i++) {
				blogPostData.append("medias[]", medias[i]);
			}
			blogPostData.append("title", title);
			blogPostData.append("body", postContent);
			blogPostData.append("home_id", homeId.toString());
			blogPostData.append("date", postDate);
			blogPostData.append("senior_ids", JSON.stringify(seniorsSelected));

			await mutate(blogPostData);
		} else {
			window.scrollTo({
				top: 0,
				left: 0,
				behavior: "smooth"
			});
			setIsError(true);
		}
	};

	const { mutate } = useMutation(
		(blogPostData: any) =>
			http.post(`${url.HOMES_URL}/blog_posts`, blogPostData, {
				onUploadProgress: progressEvent => {
					setProgress({ size: progressEvent.loaded, maxSize: progressEvent.total + 1500 });
				}
			}),
		{
			onSettled: () => {
				queryClient.invalidateQueries("blog_posts");
			},
			onSuccess: res => {
				blogDispatch({
					type: "ADD_POST",
					payload: { post: res.data.blog_post, locale: userState.userConnected.locale }
				});
				setIsLoading(false);
				queryClient.invalidateQueries("blog_posts");
				if (res.data.blog_post.accepted === null && canModeratePost) {
					setShowModeration({ show: true, post: res.data.blog_post });
				} else {
					window.scrollTo({
						top: 0,
						left: 0,
						behavior: "smooth"
					});
					history.push(`/post/${res.data.blog_post.id}`);
				}
			},
			onError: () => {
				window.scrollTo({
					top: 0,
					left: 0,
					behavior: "smooth"
				});
				setIsLoading(false);
				setIsError(true);
			}
		}
	);

	return (
		<>
			{isLoading ? (
				<div className="header-details center">
					<Progressbar
						size={progress.size}
						maxSize={progress.maxSize}
						isLoader={true}
						alertMessage={t("news.__addNewsLoading__")}
					/>
				</div>
			) : (
				<form onSubmit={onSubmit} encType="multipart/form-data">
					{isError ? (
						<HandleError message={t("news.__error__")} type="error" setError={setIsError} />
					) : null}

					<PostManager
						seniors={seniors}
						postContent={postContent}
						setPostContent={setPostContent}
						title={title}
						setTitle={setTitle}
						selectedDate={selectedDate}
						setSelectedDate={setSelectedDate}
						medias={medias}
						setMedias={setMedias}
						setSeniorsSelected={setSeniorsSelected}
						uploadSize={uploadSize}
						setUploadSize={setUploadSize}
						seniorsSelected={seniorsSelected}
						initialSeniorsSelected={[]}
						homeUnits={homeUnits}
						editMedias={true}
						initialMedias={[]}
					/>
				</form>
			)}
			<ModeratePostModal
				show={showModeration}
				setShow={setShowModeration}
				userLocale={userState.userConnected.locale}
				blogDispatch={blogDispatch}
			/>
		</>
	);
}
