import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import { SeniorInterface } from "../interfaces/UserStateInterface";
import * as url from "../constants/Url";
import http from "../services/interceptor";
import { getActivityAction } from "../constants/Activities";
import { handleDate } from "@e4ia/e4link_modules.datetime";
import { BsFillPinAngleFill } from "react-icons/bs";
import { OccupationInterface } from "../interfaces/EventInterface";

interface OccupationDetailsProps {
	seniors: SeniorInterface[];
}

export default function OccupationDetails({ seniors }: OccupationDetailsProps) {
	const { t } = useTranslation("common");
	const { eventId } = useParams<{ eventId?: string }>();
	const { occupationId } = useParams<{ occupationId?: string }>();
	const [occupation, setOccupation] = useState<OccupationInterface | null>(null);

	useEffect(() => {
		http
			.get(`${url.BASE_URL}/occupations/${occupationId}`)
			.then(res => {
				const result = res.data.occupation;
				const seniorIds = result.senior_ids;
				const attendees = seniors.filter((senior: SeniorInterface) => seniorIds.includes(senior.id));
				const occupationWithSeniors = {
					...result,
					seniors: attendees,
					activity: getActivityAction(result.action_id)
				};
				setOccupation(occupationWithSeniors);
			})
			.catch(() => setOccupation(null));
	}, [occupationId]);

	return (
		<>
			<div className="d-flex justify-content-between">
				<div className="mr-2">
					<h1>{t("calendar.__workshop__")}</h1>
					<div className="separator"></div>
				</div>
				<div className="right mr-2">
					<Link to="/calenday">
						<button className="btn btn-outline-secondary" type="button">
							{t("common.__back__")}
						</button>
					</Link>
				</div>
			</div>
			{occupation ? (
				<div className="content-layout">
					<h2>
						<BsFillPinAngleFill className="mr-2" />
						{occupation.activity}
					</h2>

					<p className="meta">
						{t("common.__date__")} : {handleDate(occupation.datetime, "PPPp")}
					</p>
					<h3 className="mt-4">{t("calendar.__seniorsIn__")} </h3>
					{occupation.seniors.length > 0 ? (
						occupation.seniors.map((senior: SeniorInterface) => <li key={senior.id}>{senior.name}</li>)
					) : (
						<p className="meta">{t("calendar.__noAttendees__")}</p>
					)}
					{eventId && occupation ? (
						<div className="mt-4">
							<Link to={`/occupation/${eventId}/${occupation.id}`}>
								<button className="btn btn-outline-primary" type="button">
									{t("common.__update__")}
								</button>
							</Link>
						</div>
					) : null}
				</div>
			) : (
				<p>{t("common.__noData__")}</p>
			)}
		</>
	);
}
