import React, { useRef, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useInfiniteQuery } from "react-query";
import { BlogContext } from "../../../context/BlogContext";
import useIntersectionObserver from "../../../hooks/useIntersectionObserver";
import PostContainer from "./PostContainer";
import Loader from "../../../layout/Loader";
import OccupationContainer from "./OccupationContainer";
import http from "../../../services/interceptor";
import * as url from "../../../constants/Url";
import "./News.scss";

export default function Posts({ seniors, userState }) {
	const { t } = useTranslation("common");
	const { blogDispatch } = useContext(BlogContext);
	const homeId = userState.userConnected.home_id;

	const {
		status,
		data,
		isFetching,
		isFetchingNextPage,
		fetchNextPage,
		hasNextPage
	} = useInfiniteQuery(
		["blog_posts", homeId],
		async ({ pageParam = 0 }) => {
			const res = await http.get(`${url.HOMES_URL}/${homeId}/accepted/blog_posts?idxo=` + pageParam);
			return res.data;
		},
		{
			getPreviousPageParam: firstPage => firstPage.previousId ?? false,
			getNextPageParam: lastPage => lastPage.nextId ?? false
		}
	);

	const loadMoreButtonRef = useRef();

	useIntersectionObserver({
		target: loadMoreButtonRef,
		onIntersect: fetchNextPage,
		enabled: hasNextPage
	});

	const handleData = post => {
		switch (post.type) {
			case 7:
				return <OccupationContainer occupation={post.occupation} seniors={seniors} />;
			case 1:
				return (
					<PostContainer
						seniors={seniors}
						post={post.blog_post}
						displayStatus={false}
						userState={userState}
						blogDispatch={blogDispatch}
					/>
				);
			default:
				return;
		}
	};

	return (
		<div className="flow-layout">
			{status === "loading" ? (
				<Loader />
			) : status === "error" ? (
				<p>{t("common.__noData__")}</p>
			) : (
				data &&
				data.pages[0].data.length > 0 && (
					<>
						{data.pages.map(group =>
							group.data.map((post, index) => (
								<div key={index} className="social-card">
									{handleData(post)}
								</div>
							))
						)}
						<div className="center">
							<button
								ref={loadMoreButtonRef}
								onClick={() => fetchNextPage()}
								disabled={!hasNextPage || isFetchingNextPage}
								className="light-button">
								{isFetchingNextPage ? t("common.__loading__") : hasNextPage ? t("news.__more__") : null}
							</button>
							{isFetching && !isFetchingNextPage ? t("common.__loading__") : null}
						</div>
					</>
				)
			)}
		</div>
	);
}
