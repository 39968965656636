import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { BsCameraVideo, BsCardImage } from "react-icons/bs";
import { onUploadFiles, onUploadVideo } from "../../../utils/Blogging";
import { FaRegFilePdf } from "react-icons/fa";
import PicturesManager from "./PicturesManager";
import VideosManager from "./VideosManager";
import PdfsManager from "./PdfsManager";
import { Seniorsidentificationmanager } from "@e4ia/e4link_modules.seniorsidentificationmanager";
import { RichEditor } from "@e4ia/e4link_modules.richeditor";
import { Progressbar } from "@e4ia/e4link_modules.progressbar";
import { displayMeta } from "../BlogUtils";
import { HomeUnitsInterface, SeniorInterface } from "../../../interfaces/UserStateInterface";
import { FileInterface } from "../../../interfaces/BlogInterface";

interface PostManagerProps {
	editMedias: boolean;
	setEditMedias?: Function;
	seniors: SeniorInterface[];
	initialSeniorsSelected?: SeniorInterface[];
	seniorsSelected: number[];
	setSeniorsSelected: Function;
	postContent: string;
	setPostContent: Function;
	title: string;
	setTitle: Function;
	selectedDate: string;
	setSelectedDate: Function;
	initialMedias?: FileInterface[];
	setInitialMedias?: Function;
	medias: any;
	setMedias: Function;
	uploadSize: number;
	setUploadSize: Function;
	homeUnits: HomeUnitsInterface[];
}

export default function PostManager({
	editMedias,
	setEditMedias,
	seniors,
	initialSeniorsSelected,
	setSeniorsSelected,
	postContent,
	setPostContent,
	title,
	setTitle,
	selectedDate,
	setSelectedDate,
	medias,
	setMedias,
	initialMedias,
	setInitialMedias,
	uploadSize,
	setUploadSize,
	seniorsSelected,
	homeUnits
}: PostManagerProps) {
	const { t } = useTranslation("common");
	const history = useHistory();

	return (
		<div className="row mt-3">
			{!editMedias && setEditMedias ? (
				<>
					<div className="col-12">
						<div className="header-details">
							<div className="social-info">{displayMeta(initialMedias)}</div>
							<div className="header-details-button">
								<button type="button" className="btn btn-primary" onClick={() => setEditMedias(true)}>
									{t("news.__addEditMedias__")}
								</button>
							</div>
						</div>
					</div>
				</>
			) : null}
			<div className="col-12 mb-3">
				<label htmlFor="title">{t("news.__title__")}</label>
				<input
					type="text"
					name="title"
					id="title"
					aria-describedby="titleHelp"
					className="form-control  form-control-lg"
					onChange={e => setTitle(e.target.value)}
					defaultValue={title}
				/>
				<small id="titlelHelp" className="form-text text-muted right">
					{t("common.__required__")}
				</small>
			</div>

			{editMedias ? (
				<>
					<div className="col-4">
						<label htmlFor="blog-pictures" className="upload-button">
							<BsCardImage className="download-img-small" />
							<p className="meta">{t("news.__uploadPicture__")}</p>
							<input
								type="file"
								id="blog-pictures"
								name="file"
								accept="image/x-png,image/gif,image/jpeg,image/jpg;capture=camera"
								style={{ display: "none" }}
								onChange={e => onUploadFiles(e, medias, setMedias, uploadSize, setUploadSize)}
								multiple
							/>
						</label>
					</div>

					<div className="col-4">
						<label htmlFor="video" className="upload-button">
							<BsCameraVideo className="download-img-small" />
							<p className="meta">{t("news.__uploadVideo__")}</p>
							<input
								type="file"
								id="video"
								name="video"
								accept="video/mp4"
								style={{ display: "none" }}
								onChange={e => onUploadVideo(e, medias, setMedias, uploadSize, setUploadSize)}
							/>
						</label>
					</div>

					<div className="col-4">
						<label htmlFor="blog-pdf" className="upload-button">
							<FaRegFilePdf className="download-img-small" />
							<p className="meta">{t("news.__uploadDocument__")}</p>
							<input
								type="file"
								id="blog-pdf"
								name="file"
								accept="application/pdf"
								style={{ display: "none" }}
								onChange={e => onUploadFiles(e, medias, setMedias, uploadSize, setUploadSize)}
								multiple
							/>
						</label>
					</div>

					{uploadSize ? (
						<div className="col-12 mt-3 mb-3">
							<label>{t("news.__yourUploads__")} :</label>
							<Progressbar
								size={uploadSize}
								maxSize={250000000}
								alertSize={200000000}
								isLoader={false}
								alertMessage={t("news.__limitSizeReached__")}
							/>
						</div>
					) : null}

					<div className="col-12">
						<VideosManager
							medias={medias}
							setMedias={setMedias}
							setUploadSize={setUploadSize}
							initialMedias={initialMedias}
							setInitialMedias={setInitialMedias}
						/>

						<PdfsManager
							medias={medias}
							setMedias={setMedias}
							setUploadSize={setUploadSize}
							initialMedias={initialMedias}
							setInitialMedias={setInitialMedias}
						/>

						<PicturesManager
							medias={medias}
							setMedias={setMedias}
							setUploadSize={setUploadSize}
							initialMedias={initialMedias}
							setInitialMedias={setInitialMedias}
						/>
					</div>
				</>
			) : null}
			<div className="col-12">
				<RichEditor initialContent={postContent} setPostContent={setPostContent} />

				<Seniorsidentificationmanager
					seniors={seniors}
					initialSeniorsSelected={initialSeniorsSelected}
					setSeniorsSelected={setSeniorsSelected}
					homeUnits={homeUnits}
					seniorsSelected={seniorsSelected}
					text={{
						homeUnitSelection: "Sélectionnez un service",
						senior: t("common.__senior__"),
						seniors: t("common.__seniors__"),
						noSeniorSelected: t("news.__noSeniorSelected__"),
						warningSeniorFocused: t("news.__warningSeniorFocused__"),
						selectSenior: t("news.__selectSenior__"),
						homeUnit: t("common.__homeUnit__"),
						filterBy: t("common.__filterBy__"),
						selectedSeniors: t("news.__selectedSeniors__")
					}}
				/>

				<label htmlFor="selectedDate">{t("common.__date__")} :</label>
				<input
					type="date"
					name="selectedDate"
					id="selectedDate"
					aria-describedby="titleHelp"
					className="form-control  form-control-lg"
					defaultValue={selectedDate}
					onChange={e => setSelectedDate(e.target.value)}
				/>
				<small id="titlelHelp" className="form-text text-muted">
					{t("common.__required__")}
				</small>
			</div>
			<div className="col-12 mt-4 mb-4">
				<button className="btn btn-outline-primary mr-2" onClick={() => history.goBack()} type="button">
					{t("common.__back__")}
				</button>
				<button className="btn btn-primary" type="submit">
					{t("common.__save__")}
				</button>
			</div>
		</div>
	);
}
